import React from "react";

import "./ThoughtCitySection16.css";

import tc36 from "../../../assets/images/thoughtcity/36-min.png";

const ThoughtCitySection16 = () => {
  return (
    <div className="thoughtcitysection16">
      <img src={tc36} alt="thoughtcity" />
      <p>
        The <strong>social media posts</strong> are <br /> thought to be little{" "}
        <br /> lighthearted comic strips and <br /> meme templates featuring the{" "}
        <br /> mascot. They discuss the <br /> game's characteristics and the{" "}
        <br /> many scenarios in which it can <br /> be played.
      </p>
    </div>
  );
};

export default ThoughtCitySection16;
