import bioVideo from "../assets/videos/bioVideo.mp4";
import workVideo from "../assets/videos/workVideo.mp4";
import sandboxVideo from "../assets/videos/sandboxVideo.mp4";
import behanceVideo from "../assets/videos/behanceVideo.mp4";

import bioImage from "../assets/Apple devices.PNG";
import workImage from "../assets/Apple devices 2.PNG";
import sandboxImage from "../assets/Apple devices 3.PNG";
import behanceImage from "../assets/Apple devices 4.PNG";

export const navOptionData = [
  {
    text: "Bio",
    video: bioVideo,
    image: bioImage,
    borderColor: "#6496F5",
    navigateTo: "/bio",
  },
  {
    text: "Work",
    video: workVideo,
    image: workImage,
    borderColor: "#F5D103",
    navigateTo: "/work",
  },
  {
    text: "Sandbox",
    video: sandboxVideo,
    image: sandboxImage,
    borderColor: "#F6BC71",
    navigateTo: "/sandbox",
  },
  {
    text: "Behance",
    video: behanceVideo,
    image: behanceImage,
    borderColor: "#F66E9E",
    navigateTo: "/behance",
  },
];
