import React, { useEffect } from "react";

import "./CharliePage.css";

import cl1 from "../../../assets/images/charlie/cl-1.png";
import cl10 from "../../../assets/images/charlie/cl-10.png";
import cl2 from "../../../assets/images/charlie/cl-2.png";
import cl21Video from "../../../assets/images/charlie/cl-21 video.mp4";
import cl22 from "../../../assets/images/charlie/cl-22.png";
import cl3Video from "../../../assets/images/charlie/cl-3 video.mp4";
import cl4 from "../../../assets/images/charlie/cl-4.png";
import cl5 from "../../../assets/images/charlie/cl-5.png";
import cl7 from "../../../assets/images/charlie/cl-7.png";
import cl8Video from "../../../assets/images/charlie/cl-8 video.mp4";
import cl9 from "../../../assets/images/charlie/cl-9.png";
import cl6Video from "../../../assets/images/charlie/final construction (1).mp4";

import brandManual from "../../../assets/images/charlie/brand manual.png";

import CharlieSection10 from "../../../Components/Charlie/CharlieSection10/CharlieSection10";
import CharlieSection11 from "../../../Components/Charlie/CharlieSection11/CharlieSection11";
import CharlieSection12 from "../../../Components/Charlie/CharlieSection12/CharlieSection12";
import CharlieSection9 from "../../../Components/Charlie/CharlieSection9/CharlieSection9";
import Footer from "../../../Components/Footer/Footer";
import { useWindowDimensions } from "../../../assets/Helpers/helpers";

import { timer } from "../../../App";

const CharliePage = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    const setLoader = async () => {
      await timer(4000);
    };
    setLoader();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="charliepage">
      {/* SECTION-1 */}
      <section className="topSection__sec1">
        <span>2021</span>
        <div className="topSection__sec1__con">
          <p>
            <span style={{ color: "#91FF19", fontWeight: "500" }}>Charlie</span>{" "}
            is a cloud kitchen bakery whose brand {width > 750 && <br />}{" "}
            language is a literal translation of a "Sugar Rush" or a{" "}
            {width > 750 && <br />} "Happy High." This identity is vibrant,
            whimsical and {width > 750 && <br />} captures the adult side of
            playful desserts.
          </p>
          <div className="topSection__sec1__con-right">
            <div>
              <h4>Client</h4>
              <p>
                Collaboration with {width > 750 && <br />} Hustlin{" "}
                {width < 500 && <br />} Media for {width > 750 && <br />} Fusion
                Foods
              </p>
            </div>
            <div>
              <h4>Service</h4>
              <p>
                Branding, Packaging, <br /> Marketing
              </p>
            </div>
          </div>
        </div>
      </section>
      <img src={cl1} alt="filled-with-white" className="charliepage__sec2" />

      {/* SECTION-3 */}

      <section className="charliepage__sec3">
        <div className="charliepage__sec3-top">
          <img src={cl2} alt="charliepage" />
          <div>
            <h1>
              bold-elegant <br />
              genz-millenial <br />
              adult-childlike <br />
              fresh-nostalgic <br />
              playful-belonging <br />
              simple-celebratory
            </h1>
            <p>
              These are the keywords that the client has <br /> provided.
              Hustlin Media gave briefs at each <br /> level based on their
              client's needs.
            </p>
          </div>
        </div>
        <p>
          These are the keywords that the client has <br /> provided. Hustlin
          Media gave briefs at each <br /> level based on their client's needs.
        </p>
      </section>

      <video
        loop
        muted
        autoPlay
        type="video/mp4"
        className="charliepage__sec4"
        playsInline
      >
        <source src={cl3Video} type="video/mp4" />
      </video>

      {/* SECTION-5 */}
      <section className="charliepage__sec5">
        <p>
          Each letter was <br /> crafted to be bold and <br /> nostalgic while
          still <br /> being simple.
        </p>
        <img src={cl4} alt="filled-with-white" />
      </section>

      {/* SECTION-6 */}
      <section className="charliepage__sec6">
        <img src={cl5} alt="charlie" className="charliepage__sec6-top" />

        <video loop muted autoPlay type="video/mp4" playsInline>
          <source src={cl6Video} type="video/mp4" />
        </video>
        <img src={cl7} alt="charlie" className="charliepage__sec6-bottom" />
      </section>

      {/* SECTION-7 */}
      <section className="charliepage__sec7">
        <p>
          This brand's colours were <br /> inspired by GenZ <br /> and Millenial
          favourite:
        </p>
        <video loop muted autoPlay type="video/mp4" playsInline>
          <source src={cl8Video} type="video/mp4" />
        </video>
      </section>

      {/* SECTION-8 */}
      <section className="charliepage__sec8">
        {/* <h1>
          brand <br />{" "}
          <span
            style={{
              color: "black",
              WebkitTextStrokeWidth: "1px",
              WebkitTextStrokeColor: "white",
            }}
          >
            manual
          </span>
        </h1> */}
        <img src={brandManual} alt="brand-manual-charlie" />
        <div>
          <span>color system</span>
          <img src={cl9} alt="charlie" />
        </div>
        <div>
          <img src={cl10} alt="charlie" />
          <span>typography</span>
          <p>
            “Work Sans” <br /> complements the <br /> logotype's classic style{" "}
            <br /> while reflecting the <br /> identity's quirkiness.
          </p>
        </div>
      </section>

      <CharlieSection9 />
      <CharlieSection10 />
      <CharlieSection11 />
      <CharlieSection12 />
      <video
        loop
        muted
        autoPlay
        type="video/mp4"
        className="charliepage__sec13"
        playsInline
      >
        <source src={cl21Video} type="video/mp4" />
      </video>
      <img
        src={cl22}
        alt="filled-with-white"
        style={{ width: "100%" }}
        className="charliepage__sec2"
      />

      <Footer route="/work/thoughtcity" />
    </div>
  );
};

export default CharliePage;
