import React from "react";

import "./ThoughtCitySection17.css";

import tc37 from "../../../assets/images/thoughtcity/37-min.png";

const ThoughtCitySection17 = () => {
  return (
    <div className="thoughtcitysection17">
      <img src={tc37} alt="thoughtcity" />
      <p>
        This YouTube clip gives a taste of <br /> the game's atmosphere by
        showing <br /> a group of friends absorbed in <br /> ThoughtCity at
        home. The genre of <br /> music used [lo-fi] is like the jazz for <br />{" "}
        this generation. It has a nostalgic <br /> undertone that complements
        the <br /> bright colours and portrays the <br /> overall mood of
        "ThoughtCity."
      </p>
    </div>
  );
};

export default ThoughtCitySection17;
