import React from "react";

import "./CharlieSection10.css";

import cl14 from "../../../assets/images/charlie/cl-14.png";
import cl15 from "../../../assets/images/charlie/cl-15.png";
import cl16 from "../../../assets/images/charlie/cl-16.png";
import cl17 from "../../../assets/images/charlie/cl-17.png";

import ReactPlayer from "react-player";

const CharlieSection10 = () => {
  return (
    <div className="charliesection10">
      <img src={cl14} alt="charlie" />
      <img src={cl15} alt="charlie" />
      <img src={cl16} alt="charlie" />
      <img src={cl17} alt="charlie" />
      <div>
        <div>
          <ReactPlayer
            url="https://vimeo.com/730043452"
            className="charliesection10-vimeo"
            width="100%"
            height="100%"
            controls
          />
        </div>
      </div>
      <p>
        The brush's texture adds to <br /> the upbeat mood that was <br />{" "}
        envisioned. This encourages <br /> their customers to give <br />{" "}
        Charlie's products as gifts. <br /> <br />
        *colors are way cuter irl.{" "}
      </p>
    </div>
  );
};

export default CharlieSection10;
