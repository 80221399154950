import React from "react";

import "./ThoughtCitySection5.css";

import tc4 from "../../../assets/images/thoughtcity/4-min.png";

const ThoughtCitySection5 = () => {
  return <img src={tc4} alt="thoughtcity" className="thoughtcitysection5" />;
};

export default ThoughtCitySection5;
