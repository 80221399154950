import React from "react";

import "./ThoughtCitySection10.css";

import tc11 from "../../../assets/images/thoughtcity/11-min.png";
import tc12 from "../../../assets/images/thoughtcity/11 packaging-min.png";
import tc13 from "../../../assets/images/thoughtcity/12-min.png";

const ThoughtCitySection10 = () => {
  return (
    <section className="thoughtcitysection10">
      <div>
        <img src={tc12} alt="thoughtcity" />
        <img src={tc11} alt="thoughtcity" />
      </div>
      <div>
        <img src={tc13} alt="thoughtcity" />
        <p>
          <strong>
            All depictions of this identity <br /> (Packaging, cards)
            incorporate the <br /> things and possessions that people <br />{" "}
            listed in the survey.
          </strong>{" "}
          <br /> <br />
          The game play is described briefly on the <br /> back of the box. The
          front of the card <br /> features a picture of the "home" of the{" "}
          <br /> cards - the package on the packaging :P. <br /> Surrounding the
          home are different <br /> objects. The melting liquid is a metaphor{" "}
          <br /> for the melting of ice. (Ice-Breaking)
        </p>
      </div>
    </section>
  );
};

export default ThoughtCitySection10;
