import React, { useEffect } from "react";
import Footer from "../../../Components/Footer/Footer";
import ThoughtCitySection10 from "../../../Components/ThoughtCity/ThoughtCitySection10/ThoughtCitySection10";
import ThoughtCitySection11 from "../../../Components/ThoughtCity/ThoughtCitySection11/ThoughtCitySection11";
import ThoughtCitySection12 from "../../../Components/ThoughtCity/ThoughtCitySection12/ThoughtCitySection12";
import ThoughtCitySection13 from "../../../Components/ThoughtCity/ThoughtCitySection13/ThoughtCitySection13";
import ThoughtCitySection14 from "../../../Components/ThoughtCity/ThoughtCitySection14/ThoughtCitySection14";
import ThoughtCitySection15 from "../../../Components/ThoughtCity/ThoughtCitySection15/ThoughtCitySection15";
import ThoughtCitySection16 from "../../../Components/ThoughtCity/ThoughtCitySection16/ThoughtCitySection16";
import ThoughtCitySection17 from "../../../Components/ThoughtCity/ThoughtCitySection17/ThoughtCitySection17";
import { useWindowDimensions } from "../../../assets/Helpers/helpers";

import ThoughtCitySection2 from "../../../Components/ThoughtCity/ThoughtCitySection2/ThoughtCitySection2";
import ThoughtCitySection3 from "../../../Components/ThoughtCity/ThoughtCitySection3/ThoughtCitySection3";
import ThoughtCitySection4 from "../../../Components/ThoughtCity/ThoughtCitySection4/ThoughtCitySection4";
import ThoughtCitySection5 from "../../../Components/ThoughtCity/ThoughtCitySection5/ThoughtCitySection5";
import ThoughtCitySection6 from "../../../Components/ThoughtCity/ThoughtCitySection6/ThoughtCitySection6";
import ThoughtCitySection7 from "../../../Components/ThoughtCity/ThoughtCitySection7/ThoughtCitySection7";
import ThoughtCitySection8 from "../../../Components/ThoughtCity/ThoughtCitySection8/ThoughtCitySection8";
import ThoughtCitySection9 from "../../../Components/ThoughtCity/ThoughtCitySection9/ThoughtCitySection9";

import ReactPlayer from "react-player";

import { useNavigate } from "react-router-dom";
import { timer } from "../../../App";
import "./ThoughtCityPage.css";

const ThoughtCityPage = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  useEffect(() => {
    const setLoader = async () => {
      await timer(4000);
    };
    setLoader();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="thoughtcitypage">
      {/* SECTION-1 */}
      <section className="topSection__sec1">
        <span>2020</span>
        <div className="topSection__sec1__con">
          <p>
            <span style={{ color: "#9100F0", fontWeight: "500" }}>
              ThoughtCity
            </span>{" "}
            is a conversational card game that is used to{" "}
            {width > 750 && <br />} break the ice. The focus of this project is
            on its dreamy {width > 750 && <br />} illustration-based branding,
            packaging, and advertising.
            {width > 750 && <br />}{" "}
            <span
              onClick={() => navigate("/work/thoughtcity/document")}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Here's
            </span>{" "}
            a rundown of the game's design and research.
          </p>
          <div className="topSection__sec1__con-right">
            <div>
              <h4>Client</h4>
              <p>
                Graduation <br /> Project
              </p>
            </div>
            <div>
              <h4>Service</h4>
              <p>
                Branding, Packaging, <br /> Advertising
              </p>
            </div>
          </div>
        </div>
      </section>
      <ThoughtCitySection2 />
      <ThoughtCitySection3 />
      <ThoughtCitySection4 />
      <ThoughtCitySection5 />
      <ThoughtCitySection6 />
      <ThoughtCitySection7 />
      <ThoughtCitySection8 />
      <ThoughtCitySection9 />
      <ThoughtCitySection10 />
      <ThoughtCitySection11 />
      <ThoughtCitySection12 />
      <ThoughtCitySection13 />
      <ThoughtCitySection14 />
      <ThoughtCitySection15 />
      <ThoughtCitySection16 />
      <ThoughtCitySection17 />

      <div className="thoughtcitysection18">
        <ReactPlayer
          url="https://vimeo.com/728216851"
          width="100%"
          height="100%"
          className="thoughtcitysection18-react-player"
          controls
        />
      </div>

      <Footer route="/work/spoofsense" />
    </div>
  );
};

export default ThoughtCityPage;
