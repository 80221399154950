import charlie from "../assets/images/charlie.png";
import thoughtCity from "../assets/images/thoughtCity.png";
import spoofsense from "../assets/images/spoofsense.png";

export const workData = [
  {
    projectImage: charlie,
    projectType: "Client Project",
    projectTitle: "Charlie",
    projectSubTitle: "Bakery Branding & Marketing Design",
    projectColor: "#92FF1A",
    projectRoute: "/work/charlie",
  },
  {
    projectImage: thoughtCity,
    projectType: "Personal Project",
    projectTitle: "ThoughtCity",
    projectSubTitle: "Game Branding & Advertising Design",
    projectColor: "#9206F5",
    projectRoute: "/work/thoughtCity",
  },
  {
    projectImage: spoofsense,
    projectType: "Client Project",
    projectTitle: "SpoofSense",
    projectSubTitle: "AI Product Branding & 3D Website Design",
    projectColor: "#0C6FF0",
    projectRoute: "/work/spoofsense",
  },
];
