import React from "react";
import { useWindowDimensions } from "../../../assets/Helpers/helpers";

import "./SpoofSenseSideOSide.css";

const SpoofSenseSideOSide = ({
  img,
  p,
  imageText,
  vid,
  sandbox,
  moreText,
  img2,
}) => {
  const { width } = useWindowDimensions();
  return (
    <div
      style={{
        flexDirection: width > 750 ? (imageText ? "row" : "") : "",
        justifyContent: sandbox
          ? width > 750
            ? imageText
              ? "start"
              : "end"
            : ""
          : "",
        gap: sandbox ? (width > 750 ? "2rem" : width > 200 ? ".8rem" : "") : "",
      }}
      className="spoofsensesideoside"
    >
      {vid ? (
        <video
          loop
          muted
          autoPlay
          style={{
            width: sandbox ? (width > 750 ? "50%" : "") : "",
            borderRadius: sandbox ? "0" : "",
          }}
          type="video/mp4"
          playsInline
        >
          <source src={vid} type="video/mp4" />
        </video>
      ) : (
        <img
          src={img}
          alt="thoughtcity"
          style={{ width: sandbox ? (width > 750 ? "60%" : "") : "" }}
        />
      )}

      <div>
        {img2 && (
          <img
            src={img2}
            alt="spoofsense"
            style={{ width: "80%", marginBottom: "1.5rem" }}
          />
        )}
        <p
          style={{
            fontFamily: sandbox ? "Familjen Grotesk" : "",
            letterSpacing: sandbox ? "-0.05em" : "",
          }}
        >
          {p &&
            p.map((text, index) => (
              <span
                key={index}
                style={{
                  fontSize: sandbox
                    ? width > 1100
                      ? "2.2rem"
                      : width > 1000
                      ? "2rem"
                      : width > 800
                      ? "1.6rem"
                      : width > 300
                      ? "1.5rem"
                      : ""
                    : "",
                }}
              >
                {" "}
                {text} <br />{" "}
              </span>
            ))}
        </p>

        <p
          style={{
            fontFamily: sandbox ? "Familjen Grotesk" : "",
            letterSpacing: sandbox ? "-0.05em" : "",
            marginTop: "1rem",
          }}
        >
          {moreText &&
            moreText.map((text, index) => (
              <span
                key={index}
                style={{
                  fontSize: sandbox ? (width > 750 ? "1.2rem" : "") : "",
                  fontStyle: "italic",
                }}
              >
                {" "}
                {text} <br />{" "}
              </span>
            ))}
        </p>
      </div>
    </div>
  );
};

export default SpoofSenseSideOSide;
