import React from "react";

import "./ThoughtCitySection13.css";

import tc29 from "../../../assets/images/thoughtcity/29-min.png";
import tcV1 from "../../../assets/images/thoughtcity/Video promotional design 1.mp4";
import tc30 from "../../../assets/images/thoughtcity/30-min.png";
import tc31 from "../../../assets/images/thoughtcity/31-min.png";

const ThoughtCitySection13 = () => {
  return (
    <section className="thoughtcitysection13">
      <div>
        <img src={tc29} alt="thoughtcity" />
      </div>
      <div>
        <p>
          This <strong> Brand Mascot</strong> is based on the <br /> well-known
          Zoozoos and Baymax. It <br /> is a warm and welcoming thinking <br />{" "}
          cloud personified. The ballooning <br /> reflects all of the thoughts
          that are <br />
          being filled into the players. Boomer <br /> and weird sunsets
          inspired the Pink.
        </p>
        <video autoPlay loop muted playsInline>
          <source src={tcV1} type="video/mp4" />
        </video>
      </div>
      <div>
        <img src={tc30} alt="thoughtcity" />
        <p>
          These <strong>teaser posters</strong> encourage <br /> playful
          interaction with the brand <br /> and communication amongst people.{" "}
          <br /> Since the game is also intended to <br /> spark communication,
          writing <br /> thoughts and reading what others <br /> have written
          would effectively give a <br /> passive preview of the game.
        </p>
      </div>
      <div>
        <img src={tc31} alt="thoughtcity" />
      </div>
    </section>
  );
};

export default ThoughtCitySection13;
