import React from "react";
import "./CharlieSection9.css";

import cl11 from "../../../assets/images/charlie/cl-11.png";
import cl12 from "../../../assets/images/charlie/cl-12.png";
import cl13 from "../../../assets/images/charlie/cl-13.png";

const CharlieSection9 = () => {
  return (
    <section className="charliesection9">
      <div>
        <p>
          The strokes made by <br /> the brush "Fresco" <br /> were chosen for{" "}
          <br /> backgrounds and <br /> texturing after <br /> several trials.
        </p>
        <img src={cl11} alt="charlie" />
      </div>
      <img src={cl12} alt="charlie" />
      <div>
        <img src={cl13} alt="charlie" />
        <p>
          Fresco Texture is the <br /> brand's design <br /> element, and it
          should <br /> be used either alone <br /> or in a maximum of two{" "}
          <br /> colours to retain the <br /> brand's elegant image.
        </p>
      </div>
    </section>
  );
};

export default CharlieSection9;
