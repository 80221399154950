import React from "react";

import "./ThoughtCitySection3.css";

import tc2 from "../../../assets/images/thoughtcity/2-min.png";

const ThoughtCitySection3 = () => {
  return (
    <div className="thoughtcitysection3">
      <img src={tc2} alt="thoughtcity" />
      <p>
        After studying human <br /> relationships, I found that their <br />{" "}
        relationship with objects was <br /> the most fascinating. It's a <br />{" "}
        one-of-a-kind connection that <br /> functions similarly to human-{" "}
        <br /> human and human-animal <br /> relationships.
      </p>
    </div>
  );
};

export default ThoughtCitySection3;
