import React from "react";

import "./Footer.css";
import { useNavigate } from "react-router-dom";

import fin from "../../assets/38-min.png";

const Footer = ({ route, sandbox, noFin, sscr, tccr }) => {
  const navigate = useNavigate();

  return (
    <div className="footer">
      {!noFin && <img src={fin} alt="fin" />}

      <div className="footer__bottom">
        <ul>
          <a target="__blank" href="mailto:amitasanaa@gmail.com">
            <li>Mail</li>
          </a>
          <a target="__blank" href="https://instagram.com/banginsoushi">
            <li>Instagram</li>
          </a>
          <a
            target="__blank"
            href="https://www.linkedin.com/in/amita-sana-8bb2591aa"
          >
            <li>LinkedIn</li>
          </a>
          <a target="__blank" href="https://www.behance.net/amitasanaa4">
            <li>Behance</li>
          </a>
        </ul>
        <ul>
          {sandbox ? (
            <li onClick={() => navigate("/")}>Homepage</li>
          ) : sscr ? (
            <li onClick={() => navigate(route)}>
              Back to{" "}
              <span
                style={{
                  color: "#0b71f1",
                  textDecoration: "underline",
                  textDecorationColor: "#0b71f1",
                }}
              >
                SpoofSense
              </span>{" "}
            </li>
          ) : tccr ? (
            <li onClick={() => navigate(route)}>
              Back to{" "}
              <span
                style={{
                  color: "#9100f0",
                  textDecoration: "underline",
                  textDecorationColor: "#9100f0",
                }}
              >
                ThoughtCity
              </span>{" "}
            </li>
          ) : (
            <li onClick={() => navigate(route)}>
              Go to{" "}
              <span
                style={{
                  color: "#F5D103",
                  textDecoration: "underline",
                  textDecorationColor: "#F5D103",
                }}
              >
                Next Project
              </span>{" "}
            </li>
          )}
          {sandbox ? (
            <li onClick={() => navigate("/work")}>
              Go to{" "}
              <span
                style={{
                  color: "#F5D103",
                  textDecoration: "underline",
                  textDecorationColor: "#F5D103",
                }}
              >
                Work
              </span>{" "}
            </li>
          ) : (
            <li onClick={() => navigate("/sandbox")}>
              Play in the{" "}
              <span
                style={{
                  color: "#F6BC71",
                  textDecoration: "underline",
                  textDecorationColor: "#F6BC71",
                }}
              >
                Sandbox
              </span>{" "}
            </li>
          )}

          <li onClick={() => navigate("/bio")}>
            Get to know{" "}
            <span
              style={{
                color: "#75A0F3",
                textDecoration: "underline",
                textDecorationColor: "#75A0F3",
              }}
            >
              me
            </span>{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
