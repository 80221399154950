import React from "react";

import "./LandingPage.css";

import { navOptionData } from "../../data/navOptionData";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import loader from "../../assets/videos/loader.mp4";
import { useState } from "react";
import { timer } from "../../App";

const LandingPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const setLoader = async () => {
      await timer(4000);
      setIsLoading(false);
    };
    setLoader();
  }, []);

  const navigateHandler = (navigateTo) => {
    if (navigateTo === "/behance") {
      window.open("https://www.behance.net/amitasanaa4");
      return;
    }
    navigate(navigateTo);
  };

  return isLoading ? (
    <div className="loader">
      <style>{"body { background-color: #0f0f0f; }"}</style>;
      <video loop autoPlay muted type="video/mp4" playsInline>
        <source src={loader} type="video/mp4" />
      </video>
    </div>
  ) : (
    <div className="landingpage">
      <style>{"body { background-color: #0f0f0f; }"}</style>;
      <div className="landingpage__nav">
        {navOptionData &&
          navOptionData.map((option, index) => (
            <div
              key={index}
              style={{ outlineColor: option.borderColor }}
              className="landingpage__nav-option"
              onClick={() => navigateHandler(option.navigateTo)}
            >
              <h1>{option.text}</h1>
              {!window.navigator.platform.match(/^iPhone/) ? (
                <video
                  onMouseOver={(event) => event.target.play()}
                  onMouseOut={(event) => event.target.pause()}
                  loop
                  muted
                  type="video/mp4"
                  playsInline
                >
                  <source src={option.video} type="video/mp4" />
                </video>
              ) : (
                <img src={option.image} alt="amita-sana-portfolio" />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default LandingPage;
