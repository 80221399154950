import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";

import "./Header.css";

import amitaSanaTextImage from "../../assets/images/amitaSanaText.PNG";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const [showMenuOptions, setShowMenuOptions] = useState(false);
  const [mobMenuOpen, setMobMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const navigateHandler = (route) => {
    setMobMenuOpen(false);

    if (route === "/behance") {
      window.open("https://www.behance.net/amitasanaa4");
      return;
    }

    navigate(route);
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      setShowMenuOptions(true);
    } else {
      setShowMenuOptions(false);
    }
    console.log(location.pathname);
  }, [location.pathname]);

  return (
    <div className="header">
      <div
        style={{ visibility: showMenuOptions ? "visible" : "hidden" }}
        className="header__left"
      >
        <span style={{ color: "#6496F5" }} onClick={() => navigate("/bio")}>
          bio
        </span>
        <span style={{ color: "#F5D103" }} onClick={() => navigate("/work")}>
          work
        </span>
      </div>
      <div
        className="header__center"
        style={{
          margin: showMenuOptions ? "" : "0 auto",
          position: showMenuOptions ? "relative" : "",
          left: showMenuOptions ? "-.7rem" : "",
        }}
      >
        <img
          onClick={() => navigate("/")}
          src={amitaSanaTextImage}
          alt="amitaSanaText"
        />
      </div>
      <div
        style={{ visibility: showMenuOptions ? "visible" : "hidden" }}
        className="header__right"
      >
        <span style={{ color: "#F6BC71" }} onClick={() => navigate("/sandbox")}>
          sandbox
        </span>
        <span
          style={{ color: "#F66E9E" }}
          onClick={() => window.open("https://www.behance.net/amitasanaa4")}
        >
          behance
        </span>
      </div>
      <div
        className="header_ham"
        style={{ visibility: showMenuOptions ? "visible" : "hidden" }}
      >
        {!mobMenuOpen ? (
          <GiHamburgerMenu onClick={() => setMobMenuOpen(true)} />
        ) : (
          <IoCloseSharp
            style={{ fontSize: "2rem" }}
            onClick={() => setMobMenuOpen(false)}
          />
        )}
      </div>
      <div
        className="header__mobileview"
        style={{ display: mobMenuOpen ? "flex" : "none" }}
      >
        <h1
          onClick={() => navigateHandler("/bio")}
          style={{ color: "#6496F5", letterSpacing: "-0.05em" }}
        >
          bio
        </h1>
        <h1
          onClick={() => navigateHandler("/work")}
          style={{ color: "#F5D103", letterSpacing: "-0.05em" }}
        >
          work
        </h1>
        <h1
          onClick={() => navigateHandler("/sandbox")}
          style={{ color: "#F6BC71", letterSpacing: "-0.05em" }}
        >
          sandbox
        </h1>
        <h1
          onClick={() => navigateHandler("/behance")}
          style={{ color: "#F66E9E", letterSpacing: "-0.05em" }}
        >
          behance
        </h1>
        <h1
          onClick={() => navigateHandler("/")}
          style={{ letterSpacing: "-0.05em" }}
        >
          Home
        </h1>
      </div>
    </div>
  );
};

export default Header;
