import React from "react";
import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Header from "./Components/Header/Header";
import Bio from "./pages/Bio/Bio";
import SpoofsenseCarousel from "./pages/Carousels/SpoofsenseCarousel/SpoofsenseCarousel";
import ThoughtCityCarousel from "./pages/Carousels/ThoughtCityCarousel/ThoughtCityCarousel";
import LandingPage from "./pages/LandingPage/LandingPage";
import SandboxPage from "./pages/SandboxPage/SandboxPage";
import CharliePage from "./pages/Work/CharliePage/CharliePage";
import SpoofsensePage from "./pages/Work/SpoofsensePage/SpoofsensePage";
import ThoughtCityPage from "./pages/Work/ThoughtCityPage/ThoughtCityPage";
import WorkPage from "./pages/WorkPage/WorkPage";

export const timer = (delay) => new Promise((res) => setTimeout(res, delay));

const App = () => {
  return (
    <div className="app">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/work" element={<WorkPage />} />
          <Route path="/bio" element={<Bio />} />
          <Route path="/sandbox" element={<SandboxPage />} />
          <Route path="/work/charlie" element={<CharliePage />} />
          <Route path="/work/thoughtcity" element={<ThoughtCityPage />} />
          <Route path="/work/spoofsense" element={<SpoofsensePage />} />
          <Route
            path="/work/thoughtcity/document"
            element={<ThoughtCityCarousel />}
          />
          <Route
            path="/work/spoofsense/document"
            element={<SpoofsenseCarousel />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
