import React from "react";

import "./ThoughtCitySection12.css";

import tcf1 from "../../../assets/images/thoughtcity/tc-f-1.png";
import tcf2 from "../../../assets/images/thoughtcity/tc-f-2.png";
import { useWindowDimensions } from "../../../assets/Helpers/helpers";

const ThoughtCitySection12 = () => {
  const { width } = useWindowDimensions();
  return (
    <img
      src={width > 750 ? tcf1 : tcf2}
      alt="thoughtcity"
      className="thoughtcitysection12"
    />
  );
};

export default ThoughtCitySection12;
