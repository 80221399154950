import React from "react";

import "./ThoughtCitySection7.css";

import cl6 from "../../../assets/images/thoughtcity/6-min.png";
import cl7 from "../../../assets/images/thoughtcity/7-min.png";

const ThoughtCitySection7 = () => {
  return (
    <div className="thoughtcitysection7">
      <div className="thoughcitysection7__outer">
        <div className="thoughcitysection7__inner">
          <img src={cl6} alt="thoughtCity" />
          <p>Primary Typeface</p>
        </div>
        <div className="thoughcitysection7__innerdesign"></div>
      </div>
      <div className="thoughcitysection7__outer">
        <div className="thoughcitysection7__inner">
          <img style={{ width: "30%" }} src={cl7} alt="thoughtCity" />
          <p>Secondary Typeface</p>
        </div>
        <div className="thoughcitysection7__innerdesign"></div>
      </div>
    </div>
  );
};

export default ThoughtCitySection7;
