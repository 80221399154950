import React from "react";

import "./ThoughtCitySection15.css";

import tc34 from "../../../assets/images/thoughtcity/34-min.png";
import tc35 from "../../../assets/images/thoughtcity/35-min.png";

const ThoughtCitySection15 = () => {
  return (
    <div className="thoughtcitysection15__wrapper">
      <img src={tc34} alt="thoughtcity" />
      <div className="thoughtcitysection15">
        <img src={tc35} alt="thoughtcity" />
        <p>
          This brand's digital presence <br /> is critical because the target{" "}
          <br /> population is most active on <br /> social media.
        </p>
      </div>
    </div>
  );
};

export default ThoughtCitySection15;
