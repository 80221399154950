import React from "react";

import "./ThoughtCitySection2.css";

import tc1 from "../../../assets/images/thoughtcity/1-min.png";

const ThoughtCitySection2 = () => {
  return <img src={tc1} alt="thoughtcity" className="thoughtcitysection2" />;
};

export default ThoughtCitySection2;
