import React from "react";

import "./ThoughtCitySection9.css";

import tc9 from "../../../assets/images/thoughtcity/9-min.png";
import tc10 from "../../../assets/images/thoughtcity/10-min.png";

const ThoughtCitySection9 = () => {
  return (
    <section className="thoughtcitysection9">
      <div>
        <img src={tc9} alt="thoughtcity" />
        <img src={tc10} alt="thoughtcity" />
      </div>
      <p>
        Purple is known for its light-hearted <br /> nature. The lime green is
        chosen for its <br /> excitement. And for a nostalgic feel, neon <br />{" "}
        blue and boomer pink are used.
      </p>
    </section>
  );
};

export default ThoughtCitySection9;
