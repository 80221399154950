import tca from "../assets/images/thoughtcity/carousel/a-min.jpg";
import tcb from "../assets/images/thoughtcity/carousel/b-min.jpg";
import tcc from "../assets/images/thoughtcity/carousel/c-min.jpg";
import tcd from "../assets/images/thoughtcity/carousel/d-min.jpg";
import tce from "../assets/images/thoughtcity/carousel/e-min.jpg";
import tcf from "../assets/images/thoughtcity/carousel/f-min.jpg";
import tcg from "../assets/images/thoughtcity/carousel/g-min.jpg";
import tch from "../assets/images/thoughtcity/carousel/h-min.jpg";
import tci from "../assets/images/thoughtcity/carousel/i-min.jpg";
import tcj from "../assets/images/thoughtcity/carousel/j-02-min.jpg";
import tck from "../assets/images/thoughtcity/carousel/k-min.jpg";
import tcl from "../assets/images/thoughtcity/carousel/l-04-min.jpg";
import tcm from "../assets/images/thoughtcity/carousel/m-min.jpg";
import tcn from "../assets/images/thoughtcity/carousel/n-min.jpg";
import tco from "../assets/images/thoughtcity/carousel/o-01-min.jpg";
import tcp from "../assets/images/thoughtcity/carousel/p-min.jpg";
import tcq from "../assets/images/thoughtcity/carousel/q-min.jpg";
import tcr from "../assets/images/thoughtcity/carousel/r-min.jpg";
import tcs from "../assets/images/thoughtcity/carousel/s-min.jpg";
import tct from "../assets/images/thoughtcity/carousel/t-06-min.jpg";
import tcu from "../assets/images/thoughtcity/carousel/u-min.jpg";
import tcv from "../assets/images/thoughtcity/carousel/v-min.jpg";
import tcw from "../assets/images/thoughtcity/carousel/w-min.jpg";
import tcx from "../assets/images/thoughtcity/carousel/x-10-min.jpg";

export const thoughtCityCarouselData = [
  tca,
  tcb,
  tcc,
  tcd,
  tce,
  tcf,
  tcg,
  tch,
  tci,
  tcj,
  tck,
  tcl,
  tcm,
  tcn,
  tco,
  tcp,
  tcq,
  tcr,
  tcs,
  tct,
  tcu,
  tcv,
  tcw,
  tcx,
];
