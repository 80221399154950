import React from "react";

import "./CharlieSection12.css";

import cl20 from "../../../assets/images/charlie/cl-20.png";

const CharlieSection12 = () => {
  return (
    <div className="charliesection12">
      <img src={cl20} alt="charlie" />
      <p>
        I created a variety <br /> of type formations <br /> and popped up with{" "}
        <br /> this lovely three-post <br /> grid format.
      </p>
    </div>
  );
};

export default CharlieSection12;
