import React, { useEffect } from "react";

import "./Bio.css";

import img1 from "../../assets/images/bio/I am Amita Sana.png";
import img2 from "../../assets/images/bio/i-am-experimenting.png";

import img3 from "../../assets/images/bio/amita-sana-dp.png";

import icon2 from "../../assets/images/bio/instagram-icon.png";
import icon3 from "../../assets/images/bio/linedin-icon.png";
import icon1 from "../../assets/images/bio/mail-icon.png";

import resume from "../../assets/resume/resume-amita-sana.pdf";

import { useWindowDimensions } from "../../assets/Helpers/helpers";

import { timer } from "../../App";

const Bio = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    const setLoader = async () => {
      await timer(4000);
    };
    setLoader();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bio">
      <div className="bio__header">
        <img src={img1} alt="bio" />
      </div>
      <div className="bio__content">
        <div>
          {width > 750 ? (
            <p>
              A Graphic Designer based in Bengaluru, India. <br /> Through my
              work, I'm currently attempting to <br /> rekindle the inner child
              in myself and others. <br />
              <img src={img2} alt="bio" /> with many visual <br /> styles and
              strive to be as versatile as possible.
              <br /> <br />
              I hold the role of a brand designer at the fintech <br /> company
              Slice during the day. During my off-hours, <br /> I'm dedicated to
              pursuing various artistic projects.
              <br /> <br />
              Get in touch with me for work, collaborations, some <br /> andhra
              food or a bowl of ramen!
            </p>
          ) : (
            <p>
              A Graphic Designer based in <br /> Bengaluru, India.Through my
              work, <br /> I'm currently attempting to rekindle <br /> the inner
              child in myself and others. <br />
              <img src={img2} alt="bio" /> with many <br /> visual styles and
              strive to be as <br /> versatile as possible.
              <br /> <br />
              I hold the role of a brand designer at <br /> the fintech company
              Slice during the <br /> day. During my off-hours, I'm dedicated{" "}
              <br /> to pursuing various artistic projects.
              <br /> <br />
              Get in touch with me for work, <br /> collaborations, some andhra
              food or a <br /> bowl of ramen!
            </p>
          )}
          <a href={resume} target="_blank" rel="noreferrer">
            <h1>View My Resume</h1>
          </a>
        </div>
        <div>
          <img src={img3} alt="bio" />
          <ul>
            <li>
              <img src={icon1} alt="bio" />
              <a href="mailto:amitasanaa@gmail.com" target="__blank">
                Mail : amitasanaa@gmail.com
              </a>
            </li>
            <li>
              <img src={icon2} alt="bio" />
              <a href="https://instagram.com/banginsoushi" target="__blank">
                Instagram : @banginsoushi
              </a>
            </li>
            <li>
              <img src={icon3} alt="bio" />
              <a
                href="https://www.linkedin.com/in/amita-sana-8bb2591aa"
                target="__blank"
              >
                Linkedin : Amita Sana
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Bio;
