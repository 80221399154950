import React, { useEffect } from "react";

import "./SandboxPage.css";

import vid1 from "../../assets/images/sandbox/vid-1.mp4";
import SpoofSenseSideOSide from "../../Components/SpoofSense/SpoofSenseSideOSide/SpoofSenseSideOSide";

import sb1 from "../../assets/images/sandbox/1b-min.png";
import sb2 from "../../assets/images/sandbox/2-min.png";
import sb3 from "../../assets/images/sandbox/3-min.png";
import sb4 from "../../assets/images/sandbox/4-min.png";
import sb5 from "../../assets/images/sandbox/5-min.png";
import sb6 from "../../assets/images/sandbox/6-min.png";
import sb7 from "../../assets/images/sandbox/7-min.png";

import sb8 from "../../assets/images/sandbox/Personal Work.png";

import sb80 from "../../assets/images/sandbox/8-min.png";
import sb81 from "../../assets/images/sandbox/8a-min.PNG";
import sb82 from "../../assets/images/sandbox/8b-min.PNG";
import sb83 from "../../assets/images/sandbox/8c-min.PNG";
import sb84 from "../../assets/images/sandbox/8d-min.PNG";
import sb85 from "../../assets/images/sandbox/8e-min.PNG";
import sb86 from "../../assets/images/sandbox/8f-min.PNG";

import sb10 from "../../assets/images/sandbox/10-min.png";
import sb9 from "../../assets/images/sandbox/9-min.png";

import sb11 from "../../assets/images/sandbox/11-min.png";
import sb12 from "../../assets/images/sandbox/12-min.png";

import vid2 from "../../assets/images/sandbox/second vid.mp4";
import vid3 from "../../assets/images/sandbox/third vid.mp4";

import sandboxAnimVideo from "../../assets/images/sandbox/sandbox-anim-video.mp4";

import { timer } from "../../App";
import Footer from "../../Components/Footer/Footer";

const SandboxPage = () => {
  useEffect(() => {
    const setLoader = async () => {
      await timer(4000);
    };
    setLoader();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="sandboxpage">
      <div className="sandboxpage__section1">
        <video loop muted autoPlay type="video/mp4" playsInline>
          <source src={vid1} type="video/mp4" />
        </video>
        <p>
          work that helps me satisfy <br /> my artistic side. <br />
          Keep scrolling and Have fun!
        </p>
      </div>

      <SpoofSenseSideOSide
        img={sb1}
        p={["Album : Flynn", "Artist : Kalmi x ", "Ireallydroppedout"]}
        sandbox
        imageText
      />

      <SpoofSenseSideOSide
        img={sb2}
        p={["Album : Surface Level", "Artist : Hanumankind", "x Kalmi"]}
        sandbox
      />

      <SpoofSenseSideOSide
        img={sb3}
        p={["Album : Capsule", "Artist : Kalmi x Trodd"]}
        sandbox
        imageText
      />

      <SpoofSenseSideOSide
        img={sb4}
        p={["Album : Genghis ", "Artist : Hanumankind x", "Kalmi"]}
        sandbox
      />

      <SpoofSenseSideOSide
        img={sb5}
        p={["Album : God Bless ", "Artist : Kalmi x Tre Ess x ", "Tienas"]}
        sandbox
        imageText
      />

      <SpoofSenseSideOSide
        img={sb6}
        p={["Album : Nee valle", "Nee valle", "Artist : Kid Move"]}
        sandbox
      />

      <SpoofSenseSideOSide
        img={sb7}
        p={["Poster Design for ", "Ukulele with Luv"]}
        sandbox
        imageText
      />

      <section className="sandboxpage__section2">
        <img src={sb8} alt="sandbox" />
        <div>
          <div className="sandboxpage__section2-carousel">
            <img src={sb80} alt="sandbox" />
            <img src={sb81} alt="sandbox" />
            <img src={sb82} alt="sandbox" />
            <img src={sb83} alt="sandbox" />
            <img src={sb84} alt="sandbox" />
            <img src={sb85} alt="sandbox" />
            <img src={sb86} alt="sandbox" />
          </div>
          <p>Swipe!</p>
        </div>
        <div>
          <p>
            some <br /> wholesome <br /> thoughts :)
          </p>
        </div>
        <div>
          <img src={sb9} alt="sandbox" />
          <img src={sb10} alt="sandbox" />
        </div>
      </section>

      <SpoofSenseSideOSide
        vid={vid2}
        p={["“Two moods ", "since 2020”"]}
        sandbox
      />

      <SpoofSenseSideOSide
        vid={vid3}
        p={["Morning/", "Evening/", "Night/", "Afternoon/"]}
        sandbox
        imageText
      />

      <SpoofSenseSideOSide
        img={sb11}
        p={["Visualised Dreams", "“Pink Clouds”"]}
        moreText={["Detailed Project on my Behance", " “Portfolio 2017-2019”"]}
        sandbox
      />

      <SpoofSenseSideOSide
        img={sb12}
        p={["Visualised Dreams", "“Surfing Highs”"]}
        moreText={["Detailed Project on my Behance", "“Portfolio 2017-2019”"]}
        sandbox
        imageText
      />

      <video
        style={{ cursor: "pointer", width: "80%" }}
        loop
        muted
        autoPlay
        type="video/mp4"
        className="charliepage__sec4"
        onClick={() => window.open("https://instagram.com/banginsoushi")}
        playsInline
      >
        <source src={sandboxAnimVideo} type="video/mp4" />
      </video>

      <Footer sandbox />
    </div>
  );
};

export default SandboxPage;
