import ss1 from "../assets/images/spoofsense/carousel/1-min.png";
import ss2 from "../assets/images/spoofsense/carousel/2-min.png";
import ss3 from "../assets/images/spoofsense/carousel/3-min.png";
import ss4 from "../assets/images/spoofsense/carousel/4-min.png";
import ss5 from "../assets/images/spoofsense/carousel/5-min.png";
import ss6 from "../assets/images/spoofsense/carousel/6-min.png";
import ss7 from "../assets/images/spoofsense/carousel/7-min.png";
import ss8 from "../assets/images/spoofsense/carousel/8-min.png";
import ss9 from "../assets/images/spoofsense/carousel/9-min.png";
import ss10 from "../assets/images/spoofsense/carousel/10-min.png";
import ss11 from "../assets/images/spoofsense/carousel/11-min.png";
import ss12 from "../assets/images/spoofsense/carousel/12-min.png";
import ss13 from "../assets/images/spoofsense/carousel/13-min.png";
import ss14 from "../assets/images/spoofsense/carousel/14-min.png";
import ss15 from "../assets/images/spoofsense/carousel/15-min.png";
import ss16 from "../assets/images/spoofsense/carousel/16-min.png";

export const spoofsenseCarouselData = [
  ss1,
  ss2,
  ss3,
  ss4,
  ss5,
  ss6,
  ss7,
  ss8,
  ss9,
  ss10,
  ss11,
  ss12,
  ss13,
  ss14,
  ss15,
  ss16,
];
