import React from "react";

import "./ThoughtCitySection4.css";

import tc3 from "../../../assets/images/thoughtcity/3-min.png";

const ThoughtCitySection4 = () => {
  return (
    <div className="thoughtcitysection4">
      <div>
        <p>
          People form attachments with <br /> things. They view their <br />{" "}
          belongings as extensions of <br /> themselves, which help them <br />{" "}
          reduce feelings of loneliness <br /> and distress.
        </p>
      </div>
      <img src={tc3} alt="thoughtcity" />
      <div>
        <p>
          During face-to-face interviews, <br /> people who appeared to be{" "}
          <br /> introverted began participating and <br />{" "}
          <strong>
            expressing themselves <br /> extensively when discussing <br />{" "}
            items and memories.{" "}
          </strong>{" "}
          This meant <br /> that talking about this topic comes <br /> easy to
          everyone. Therefore, this <br /> card game{" "}
          <strong> is centered around it. </strong>{" "}
        </p>
      </div>
    </div>
  );
};

export default ThoughtCitySection4;
