import React from "react";

import "./SpoofsenseCarousel.css";

import Carousel from "react-elastic-carousel";

import { spoofsenseCarouselData } from "../../../data/spoofsenseCarouselData";
import Footer from "../../../Components/Footer/Footer";

const SpoofsenseCarousel = () => {
  return (
    <div className="spoofsensecarousel">
      <div className="spoofsensecarousel__content">
        <p>
          2020 <br />
          This is a brief of the branding project of Chakshu.ai. <br /> To watch
          the ad video click{" "}
          <a
            target="__blank"
            href="https://www.linkedin.com/posts/spoofsense_touchlessbiometrics-facerecognition-chakshuai-activity-6719233768823042048-5dk5/?utm_source=linkedin_share&utm_medium=member_desktop_web"
            style={{ color: "white" }}
          >
            <span style={{ textDecoration: "underline" }}>here</span>
          </a>{" "}
        </p>
      </div>
      <div className="carousel-wrapper">
        <Carousel enableSwipe>
          {spoofsenseCarouselData.map((image, index) => (
            <img key={index} src={image} alt="thoughtcity" />
          ))}
        </Carousel>
      </div>
      <Footer route="/work/spoofsense" noFin sscr />
    </div>
  );
};

export default SpoofsenseCarousel;
