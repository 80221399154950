import React from "react";
import { useWindowDimensions } from "../../../assets/Helpers/helpers";

import "./SpoofSenseFullScreen.css";

const SpoofSenseFullScreen = ({ img, wid, responsive }) => {
  const { width } = useWindowDimensions();
  return (
    <img
      src={responsive ? (width > 750 ? img : responsive) : img}
      style={{ width: wid }}
      alt="spoofsense"
      className="spoofsensefullscreen"
    />
  );
};

export default SpoofSenseFullScreen;
