import React from "react";

import "./ThoughtCitySection11.css";

import tc13 from "../../../assets/images/thoughtcity/13-min.png";
import tc14 from "../../../assets/images/thoughtcity/14-min.png";
import tc15 from "../../../assets/images/thoughtcity/15-min.png";
import tc16 from "../../../assets/images/thoughtcity/16-min.png";

const ThoughtCitySection11 = () => {
  return (
    <section className="thoughtcitysection11">
      <div>
        <div className="thoughtcitysection11__item">
          <img src={tc13} alt="thoughtcity" />
          <p>Daily City</p>
        </div>
        <div className="thoughtcitysection11__item">
          <img src={tc14} alt="thoughtcity" />
          <p>Nostalgic City</p>
        </div>
        <div className="thoughtcitysection11__item">
          <img src={tc15} alt="thoughtcity" />
          <p>Reflect City</p>
        </div>
        <div className="thoughtcitysection11__item">
          <img src={tc16} alt="thoughtcity" />
          <p>Situation City</p>
        </div>
      </div>
      <div className="thoughtcitysection11__line"></div>
    </section>
  );
};

export default ThoughtCitySection11;
