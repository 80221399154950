import React from "react";

import "./ThoughtCitySection6.css";

import tc5 from "../../../assets/images/thoughtcity/5-min.png";

const ThoughtCitySection6 = () => {
  return (
    <div className="thoughtcitysection6">
      <div>
        <p>
          Logotype text set in <br /> CM Sans Serif Medium
        </p>
        <p>
          The logotype <br /> emphasises two circles, <br /> which represent a{" "}
          <br /> thought bubble and a <br /> speech bubble, to <br /> reflect
          the game's goal.
        </p>
      </div>
      <img src={tc5} alt="thoughtcity" />
      <div>
        <p>
          The logotype <br /> emphasises two circles, <br /> which represent a{" "}
          <br /> thought bubble and a <br /> speech bubble, to <br /> reflect
          the game's goal.
        </p>
      </div>
    </div>
  );
};

export default ThoughtCitySection6;
