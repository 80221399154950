import React from "react";

import "./CharlieSection11.css";

import cl19 from "../../../assets/images/charlie/cl-19.png";

const CharlieSection11 = () => {
  return (
    <div className="charliesection11">
      <p>
        The <strong>next objective</strong> was to <br /> create social media{" "}
        <br /> typography posts. The <br /> following creatives were <br />{" "}
        designed to portray the <br /> "adult side" of desserts <br /> along
        with the "fun" side.
      </p>
      <img src={cl19} alt="charlie" />
    </div>
  );
};

export default CharlieSection11;
