import React from "react";

import "./ThoughtCitySection8.css";

import tc8 from "../../../assets/images/thoughtcity/8-min.png";

const ThoughtCitySection8 = () => {
  return (
    <div className="thoughtcitysection8">
      <img src={tc8} alt="thoughtcity" />
      <p>
        This game is aimed towards <br /> adults between the ages of 18 <br />{" "}
        and 30, (students & young <br /> professionals) who socialise a lot.{" "}
        <br /> <br />
        As people enter adulthood, they dwell <br /> on their childhood, hence
        nostalgia is <br /> a typical trend among this age group. <br />{" "}
        <strong>
          {" "}
          This brand has a fun, casual but <br /> surreal visual tone that can
          evoke <br /> feelings of joy and relaxation.{" "}
        </strong>
      </p>
    </div>
  );
};

export default ThoughtCitySection8;
