import React from "react";

import "./ThoughtCityCarousel.css";

import Carousel from "react-elastic-carousel";

import { thoughtCityCarouselData } from "../../../data/thoughtCityCarouselData";
import Footer from "../../../Components/Footer/Footer";

const ThoughtCityCarousel = () => {
  return (
    <div className="thoughtcitycarousel">
      <div className="thoughtcitycarousel__content">
        <p>
          2020 <br /> This is a brief of ThoughtCity’s design research and
          process.
        </p>
      </div>
      <div className="carousel-wrapper">
        <Carousel enableSwipe>
          {thoughtCityCarouselData.map((image, index) => (
            <img key={index} src={image} alt="thoughtcity" />
          ))}
        </Carousel>
      </div>
      <Footer route="/work/thoughtcity" noFin tccr />
    </div>
  );
};

export default ThoughtCityCarousel;
