import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { timer } from "../../App";
import { workData } from "../../data/workData";

import "./WorkPage.css";

const WorkPage = () => {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(
    Array(workData.length).fill(false)
  );

  useEffect(() => {
    const setLoader = async () => {
      await timer(4000);
    };
    setLoader();
  }, []);

  const handleMouseEnter = (index) => {
    let arr = [...isHovering];
    arr[index] = true;
    setIsHovering(arr);
  };

  const handleMouseLeave = (index) => {
    let arr = [...isHovering];
    arr[index] = false;
    setIsHovering(arr);
  };

  return (
    <div className="workpage">
      <div className="workpage__projects">
        {workData &&
          workData.map((option, index) => (
            <div
              key={index}
              onMouseOver={() => handleMouseEnter(index)}
              onMouseOut={() => handleMouseLeave(index)}
              className="workpage__project"
              onClick={() => navigate(option.projectRoute)}
            >
              <img src={option.projectImage} alt={option.projectTitle} />
              <div
                style={{
                  backgroundColor: isHovering[index] ? option.projectColor : "",
                  color: isHovering[index] ? "black" : "",
                  borderColor: isHovering[index] ? option.projectColor : "",
                }}
                className="workpage__project-inner"
              >
                <p>
                  {" "}
                  <em>{option.projectType}</em>{" "}
                </p>
                <h1
                  style={{
                    color: isHovering[index] ? "black" : option.projectColor,
                  }}
                >
                  {option.projectTitle}
                </h1>
                <h3>{option.projectSubTitle}</h3>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default WorkPage;
