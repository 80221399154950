import React, { useEffect } from "react";
import { useWindowDimensions } from "../../../assets/Helpers/helpers";

import ss1 from "../../../assets/images/spoofsense/ss-1.png";
import Footer from "../../../Components/Footer/Footer";
import SpoofSenseFullScreen from "../../../Components/SpoofSense/SpoofSenseFullScreen/SpoofSenseFullScreen";
import SpoofSenseSideOSide from "../../../Components/SpoofSense/SpoofSenseSideOSide/SpoofSenseSideOSide";

import "./SpoofsensePage.css";

import ss2a from "../../../assets/images/spoofsense/2a-min.png";
import ss2b from "../../../assets/images/spoofsense/2b-min.png";
import ss3 from "../../../assets/images/spoofsense/3-min.png";
import ss4 from "../../../assets/images/spoofsense/4-min.png";
import ss5a from "../../../assets/images/spoofsense/5-a-min.png";
import ss5b from "../../../assets/images/spoofsense/5-b-min.png";
import ss6 from "../../../assets/images/spoofsense/6-min.png";
import ss7 from "../../../assets/images/spoofsense/7-min.png";
import ss8 from "../../../assets/images/spoofsense/8-min.png";
import ss9 from "../../../assets/images/spoofsense/9-min.png";

import vid1 from "../../../assets/images/spoofsense/video 1.mp4";
import vid2 from "../../../assets/images/spoofsense/video 2.mp4";
import vid3 from "../../../assets/images/spoofsense/video 3.mp4";

import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { timer } from "../../../App";

const SpoofsensePage = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  useEffect(() => {
    const setLoader = async () => {
      await timer(4000);
    };
    setLoader();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="spoofsensepage">
      {/* SECTION-1 */}
      <section className="topSection__sec1">
        <span>2022</span>
        <div className="topSection__sec1__con">
          <p>
            <span style={{ color: "#0B71F1", fontWeight: "500" }}>
              SpoofSense
            </span>{" "}
            is an AI algorithm that detects face spoofing{" "}
            {width > 750 && <br />} attacks passively. This project has a
            corporate tone with a {width > 750 && <br />} futuristic spin.
            Spoofsense is a product of Chakshu.ai, and
            {width > 750 && <br />} a brief of that branding project can be
            found{" "}
            <span
              onClick={() => navigate("/work/spoofsense/document")}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              here
            </span>
          </p>
          <div className="topSection__sec1__con-right">
            <div>
              <h4>Client</h4>
              <p>
                Chakshu.ai / Spoofsense <br /> AI Startup
              </p>
            </div>
            <div>
              <h4>Service</h4>
              <p>
                Branding, 3D <br /> Illustration, Website
              </p>
            </div>
          </div>
        </div>
      </section>

      <SpoofSenseFullScreen img={ss1} wid="90vw" />

      <SpoofSenseSideOSide
        img={ss2b}
        img2={ss2a}
        p={[
          "The logotype is either paired",
          "with the Chakshu logo or used",
          "independently.",
          "",
          "It varies depending on the",
          "space of representation.",
        ]}
      />

      <SpoofSenseFullScreen img={ss3} wid="100%" />
      <SpoofSenseFullScreen img={ss4} wid="100%" />
      <SpoofSenseFullScreen img={ss5a} wid="100%" responsive={ss5b} />
      <SpoofSenseFullScreen img={ss6} wid="100%" />

      <SpoofSenseSideOSide
        img={ss7}
        p={[
          "Color, Typography,",
          "and the conventional",
          "layout have all been",
          "depicted here.",
        ]}
        imageText
      />

      <SpoofSenseSideOSide
        vid={vid1}
        p={[
          "This illustration depicts",
          "the types of Face",
          "Spoofing attacks and the",
          "SpoofSense algorithm",
          "detecting them.",
          "",
          "These are in the order:",
          "3D mask attack, Digital",
          "Attack, Real Face, Print",
          "attacks. (Left to right)",
        ]}
        imageText
      />

      <SpoofSenseSideOSide
        vid={vid2}
        p={[
          "This one shows how",
          "SpoofSense’s anti ",
          "spoofing layer protects",
          "an enterprise by securing",
          "their Face Recognition ",
          "tasks",
        ]}
      />

      <SpoofSenseSideOSide
        vid={vid3}
        p={[
          "This render was made to",
          "demonstrate that the ",
          "SpoofSense algorithm",
          "leads to spoof-proof",
          "profile verifications.",
        ]}
        imageText
      />

      <SpoofSenseFullScreen img={ss8} wid="100%" />
      <div className="spoofsense-last">
        <img
          onClick={() => window.open("https://spoofsense.ai/")}
          src={ss9}
          alt="spoofsense"
        />
      </div>

      <div className="thoughtcitysection18">
        <ReactPlayer
          url="https://vimeo.com/729922282"
          width="100%"
          height="100%"
          className="thoughtcitysection18-react-player"
          controls
        />
      </div>

      <Footer route="/work/charlie" />
    </div>
  );
};

export default SpoofsensePage;
