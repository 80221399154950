import React from "react";

import "./ThoughtCitySection14.css";

import tc32 from "../../../assets/images/thoughtcity/32-min.png";

const ThoughtCitySection14 = () => {
  return (
    <div className="thoughtcitysection14">
      <img src={tc32} alt="thoughtcity" />
      <p>
        These posters provide people <br /> the space to express <br />{" "}
        themselves.
        <br /> <br /> <br />
        The element of a fun interaction <br /> and the giant thought bubble{" "}
        <br /> logo are meant to make the <br /> viewers remember the identity{" "}
        <br /> and create a sense of urgency <br /> and curiosity about the
        product.
      </p>
    </div>
  );
};

export default ThoughtCitySection14;
